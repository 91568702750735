<template>
  <div>
    <b-dropdown-select-account
      v-if="showAccounts && showFullView"
      :options="userAccountsFiltered"
      :hide-account-switchers="hideAccountSwitchers"
      :show-account-list="isMoreThanOneAdvertiser"
      @close="hideAccountSwitchers"
      @selected-option="onSelectAccount"
      @on-account-settings-click="onAccountSettingsClick"
    />
    <div class="ln_acct_container" @click="toggleAccounts">
      <div v-if="showFullView" class="ln_acct_txt">
        <div
          ref="longActTooltip"
          class="ln_acct_name_container box_tooltip"
          @mouseover="showTooltip = shouldShowTooltip"
          @mouseleave="showTooltip = false"
        >
          <div
            ref="nameContainer"
            class="ln_acct_name"
            :class="{ 'custom-bg-title': isMniAccount }"
            :title="getAccName()"
          >
            {{ getAccName() }}
          </div>
          <b-tooltip :tooltip-text="`${getAccName()}`" />
        </div>
        <div class="ln_acct_label" :class="{ 'custom-ln_acct_label': isMniAccount }">
          Current Account
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import accountApi from 'adready-api/api/account';
import { isDemoInstance, filterAccountsAndAdvertisersForDemo } from '~/util/utility-functions';
import BTooltip from '~/components/elements/b-tooltip.vue';
import appConsoleDataLayer from '~/api/app-console-data';
import config from '~/config';

export default {
  components: {
    BTooltip,
    BDropdownSelectAccount: () =>
      import(
        /* webpackChunkName: "b-dropdown-select-account" */ '~/components/elements/b-dropdown-select-account.vue'
      ),
  },
  props: {
    showFullView: {
      type: Boolean,
      required: true,
    },
    showAccounts: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDemoInstance,
      showTooltip: false,
    };
  },
  computed: {
    userAccounts: get('common/userAccounts'),
    advertiser: get('common/advertiser'),
    advName: get('common/demoSelectedAdvertiserName'),
    isMniAccount: get('common/isMniAccount'),
    theUser: get('common/currentUser'),
    theAccount: get('common/account'),
    demoAdvertiserMappings: get('common/demoAdvertiserMappings'),
    userAccountsFiltered() {
      const accountKeys = this.demoAdvertiserMappings.map((obj) => parseInt(obj.accountId, 10));
      const advertiserKeys = this.demoAdvertiserMappings.map((obj) =>
        parseInt(obj.advertiserId, 10)
      );
      return filterAccountsAndAdvertisersForDemo(
        this.userAccounts.filter((ua) => ua.name),
        accountKeys,
        advertiserKeys
      );
    },
    logoFileId() {
      if (!this.account || !this.account?.organization?.whitelabelEnabled) {
        return null;
      }

      return this.account?.organization?.logoFile?.id || null;
    },
    isMoreThanOneAdvertiser() {
      if (this.userAccountsFiltered?.length > 1) {
        return true;
      }
      if (this.userAccountsFiltered[0]?.advertisers?.length > 1) {
        return true;
      }
      return false;
    },
    shouldShowTooltip() {
      if (this.$refs.longActTooltip && this.$refs.nameContainer) {
        return this.$refs.nameContainer.scrollWidth > this.$refs.longActTooltip.clientWidth;
      }
      return false;
    },
  },
  methods: {
    hideAccountSwitchers() {
      this.$emit('toggle-account-switcher', false);
    },
    getAccName() {
      if (isDemoInstance()) {
        return this.advName;
      }
      return this.advertiser ? this.advertiser.name : '';
    },
    toggleAccounts() {
      this.$emit('toggle-account-switcher', !this.showAccounts);
    },
    async onSelectAccount(advertiser, demoAdvertiser) {
      EventBus.$emit('toggle-spinner', true);

      if (isDemoInstance()) {
        const theUserId = this.theUser && this.theUser.id ? this.theUser.id : '';
        accountApi.userAccount(theUserId).then((res) => {
          const accountKeys = this.demoAdvertiserMappings.map((obj) => parseInt(obj.accountId, 10));
          const advertiserKeys = this.demoAdvertiserMappings.map((obj) =>
            parseInt(obj.advertiserId, 10)
          );
          const demoAccounts = filterAccountsAndAdvertisersForDemo(
            res,
            accountKeys,
            advertiserKeys
          );
          let demoAccount = null;
          Object.values(demoAccounts).some((account) => {
            if (account && account.advertisers) {
              return Object.values(account.advertisers).some((adv) => {
                if (adv && adv.name === demoAdvertiser?.name) {
                  demoAccount = account;
                  return true;
                }
                return false;
              });
            }
            return false;
          });
          config.ziplyneChangeAdvertiser(
            this.theUser?.email,
            demoAdvertiser?.name,
            demoAccount?.name
          );
        });
      } else {
        config.ziplyneChangeAdvertiser(
          this.theUser?.email,
          advertiser?.name,
          this.theAccount?.name
        );
      }

      this.loading = true;
      this.$store.dispatch('common/setFilterCacheId', null);
      this.$emit('on-begin-switch-advertiser', advertiser);
      if (!isDemoInstance()) {
        const { name, id } = this.advertiser;
        if (advertiser.name === name && advertiser.id === id) {
          this.$emit('on-end-switch-advertiser');
          EventBus.$emit('toggle-spinner', false);
          return;
        }
      }
      try {
        if (advertiser.id) {
          await this.$store.dispatch('common/switchAdvertiser', {
            appConsoleDataLayer,
            advertiserId: advertiser.id,
          });
          this.$emit('on-select-advertiser', advertiser);
          EventBus.$emit('toggle-spinner', false);
        }
      } catch (err) {
        console.error('error switching account on dashboard ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        this.universalPixelId = null;
        EventBus.$emit('toggle-spinner', false);
      } finally {
        this.loading = false;
        this.$emit('on-end-switch-advertiser');
        EventBus.$emit('toggle-spinner', false);
      }
    },
    onAccountSettingsClick() {
      EventBus.$emit('on-account-settings');
    },
  },
};
</script>

<style lang="scss" scoped>
.ln_acct_container {
  cursor: pointer;
}
.primarycolor {
  color: var(--primarycolor);
}
button,
input,
select {
  margin: 0;
  font-family: system-ui;
}
::v-deep .inp-wrap .inp-icon {
  color: #fff !important;
  height: 34px !important;
}
::v-deep .inp-wrap .form-input {
  input {
    width: 100% !important;
    padding: 10px !important;
    margin-top: 4px !important;
    border: 1px solid #c6ced8 !important;
    height: 35px;
    border-radius: 3px;
  }
  label {
    position: absolute;
    left: 0px;
    z-index: 2;
    padding: 1px 5px;
    margin: 21px 0 0 13px;
    font-family: 'Manrope', sans-serif !important;
    font-size: 15px;
    font-weight: 300;
    color: #aaaaaa;
    pointer-events: none;
    cursor: text;
    background-color: #fff;
    transition: all 0.2s linear;
    transform: translateY(-50%);
  }
}
::-webkit-input-placeholder {
  /* Edge */
  font-family: 'Manrope', sans-serif !important;
  font-size: 13px !important;
  color: #2c3035 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'Manrope', sans-serif !important;
  font-size: 13px !important;
  color: #2c3035 !important;
}

::placeholder {
  font-family: 'Manrope', sans-serif !important;
  font-size: 13px !important;
  color: #2c3035 !important;
}
::v-deep .account-popup {
  box-shadow: unset !important;
  top: 58px !important;
  right: 12px !important;
  background: #fff !important;
  width: 280px !important;
  padding: 20px !important;
  border: 1px solid rgb(30 32 33 / 20%) !important;
  box-shadow: 1px 12px 12px rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px !important;
  font-size: 0.9em !important;
  z-index: 101 !important;
  .account-container {
    width: 240px !important;
    max-height: 328px !important;
    background-color: #fff !important;
    .mp-nav-div {
      height: 13px;
      border-bottom: 1px solid rgba(0, 20, 50, 0.1);
      margin-bottom: 10px;
    }
    .pills {
      .mp-nav-brand-link {
        display: inline-block;
        padding: 2px 8px;
        border: 1px solid #bec4d4;
        border-radius: 4px;
        font-weight: 600;
        color: #536176;
        &:hover {
          background-color: var(--primarycolor) !important;
          border: 1px solid var(--primarycolor) !important;
          color: #fff !important;
        }
      }
      .menu-dropdown-item-icon svg {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 600;
        &:hover {
          color: var(--primarycolor) !important;
        }
      }
    }
  }
  .act-swt-head {
    font-weight: 600 !important;
    color: #536176 !important;
    opacity: 0.6 !important;
  }
  .account-item {
    padding: 6px 0px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
    font-weight: 600 !important;
    color: #536176 !important;
    font-family: 'Manrope', sans-serif !important;
    font-size: 12.6px !important;
    &:hover {
      color: var(--primarycolor) !important;
    }
    .sub-account-item {
      &:hover {
        color: var(--primarycolor) !important;
      }
    }
  }
}
.flexi-dt-cont .account-popup {
  right: 25px !important;
}
::v-deep .account-popup .account-item svg[data-v-1723a3a5] {
  width: 15px !important;
  font-size: 14px !important;
}
::v-deep .logo.ss-logo-img {
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.flp-acct-drop.active {
  display: inline-block !important;
}
.flp-acct-drop {
  position: absolute;
  bottom: 111px;
  background-color: #f9fafc;
  height: 370px;
  left: 0px;
  width: 280px;
  padding: 20px;
  border: 1px solid rgb(30 32 33 / 10%);
  border-top: 2px solid var(--primarycolor);
  display: none;
}
// ::-webkit-scrollbar {
//   width: 5px;
//   height: 7px;
// }
// ::-webkit-scrollbar-button {
//   width: 0px;
//   height: 0px;
// }
// ::-webkit-scrollbar-thumb {
//   background: rgba(200, 212, 220, 0.6);
//   border: 0px none #ffffff;
//   border-radius: 10px;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background: #536176;
// }
// ::-webkit-scrollbar-thumb:active {
//   background: #536176;
// }
// ::-webkit-scrollbar-track {
//   background: transparent;
//   border: 0px none #ffffff;
//   border-radius: 50px;
// }
// ::-webkit-scrollbar-track:hover {
//   background: transparent;
// }
// ::-webkit-scrollbar-track:active {
//   background: transparent;
// }
// ::-webkit-scrollbar-corner {
//   background: transparent;
// }
// .ln_acct_wrap {
//   padding: 32px 30px;
//   border-top: 1px solid #eef1f6;
//   position: relative;
//   white-space: nowrap;
//   -webkit-transition: all 0.3s ease;
//   -moz-transition: all 0.3s ease;
//   -ms-transition: all 0.3s ease;
//   -o-transition: all 0.3s ease;
//   transition: all 0.3s ease;
// }
.ln_acct_img_wrap {
  display: inline-block;
  .ln_acct_img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
  }
}

.ln_acct_name {
  display: block;
  font-weight: 800;
  font-size: 1.06em;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  text-align: right;
  white-space: nowrap;
}
.ln_acct_name_container {
  position: relative;
  .ln_acct_tooltip {
    opacity: 0;
    position: absolute;
    background-color: #e9e9e9;
    padding: 10px;
    z-index: 2;
    text-align: center;
    border-radius: 9px;
    font-weight: 600;
    font-size: 1.06em;
    color: var(--primarydark2);
    width: 100%;
    -webkit-transition: opacity 0.6s ease-in-out;
    -moz-transition: opacity 0.6s ease-in-out;
    -ms-transition: opacity 0.6s ease-in-out;
    -o-transition: opacity 0.6s ease-in-out;
    transition: opacity 0.6s ease-in-out;
  }
}
.ln_acct_name_container:hover {
  .ln_acct_tooltip {
    opacity: 1;
  }
}
.ln_acct_label {
  display: block;
  font-weight: 600;
  font-size: 0.7em;
  color: #9fabb8;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-top: 2px;
}
.ln_acct_txt {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
}
.ln_acct_name_container.box_tooltip {
  .text_tooltip {
    right: 0 !important;
  }
}
.custom-bg-title {
  color: #ffffff !important;
  font-weight: 600;
  font-family: 'Manrope', sans-serif !important;
  width: 210px !important;
  text-align: left !important;
}
.custom-ln_acct_label {
  font-family: 'Manrope', sans-serif !important;
  text-align: left !important;
}
</style>
